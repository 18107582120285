<template>
  <div>
    <a-modal
      v-model="isShow"
      width="20%"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="提示"
      @ok="handleSubmit"
    >
      确定标记为无效账户吗？标记后，客户账号将被删除并无法登录
    </a-modal>
  </div>
</template>

<script>
import { invalidRegisteredAgentAccount } from '@/api/registered_agent'
export default {
  name: 'InvalidRegisteredAgentAccount',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'invalid_account' }),
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitting = true
      invalidRegisteredAgentAccount(this.id).then((res) => {
        if (res.code === 0) {
          // 关闭模态框
          this.isShow = false
          // 告知父组件已完成
          this.$emit('completed')
        }
        this.submitting = false
      })
    }
  }
}
</script>
